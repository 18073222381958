<script setup>
import { defineProps } from 'vue';
// composables
import { useI18n } from '@/composables/useI18n';
// components
import BaseCard from '@/components/common/card/BaseCard.vue';

const { t } = useI18n();

defineProps({
	claimRequirements: {
		type: Array,
		required: true
	}
});
</script>

<template>
	<BaseCard>
		<h3 class="smaller">{{ t('claimRequirements') }}</h3>
		<ul>
			<li v-for="text in claimRequirements" :key="text">
				{{ text }}
			</li>
		</ul>
	</BaseCard>
</template>

<style lang="scss" scoped>
h3.smaller {
	font-size: 21px;
}
ul {
	margin-bottom: 0;
}
</style>

<i18n lang="json">
{
	"en": {
		"claimRequirements": "Claim requirements"
	},
	"fr": {
		"claimRequirements": "Critères pour les demandes de règlement"
	}
}
</i18n>
