<script setup>
import { defineProps, defineEmits, computed, getCurrentInstance } from 'vue';
import { enCA, frCA } from 'date-fns/locale';
import { BRow, BCol } from 'bootstrap-vue';
import format from 'date-fns/format';
// composables
import { useI18n } from '@/composables/useI18n';
// components
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import InfoModal from '@/components/common/InfoModal.vue';
import TextModal from '@/components/common/TextModal.vue';
import ClaimRequirements from '@/components/coverage/ClaimRequirements.vue';
// utilities
import { titleCase } from '@/utils/titleCase';

const { t } = useI18n();
const root = getCurrentInstance().proxy.$root;

const props = defineProps({
	participant: {
		type: Object,
		required: true
	},
	details: {
		type: Object,
		required: true
	}
});
const emit = defineEmits(['relatedClick']);

// computed
const today = computed(() => {
	const dateFormat = root.$i18n.locale === 'en' ? 'MMMM d, yyyy' : 'd MMMM yyyy';
	return format(new Date(), dateFormat, { locale: root.$i18n.locale === 'fr' ? frCA : enCA });
});
const participantName = computed(() => {
	return `${props.participant.firstName} ${props.participant.lastName}`;
});
const tableItems = computed(() => {
	const agreements = props.details.agreements ? props.details.agreements : [];
	const items = agreements
		.filter((item) => item.descriptions)
		.map((item) => {
			let header = '';
			let order = 99;
			let content;
			let show = true;
			switch (item.type.toLowerCase()) {
				case 'coinsurance':
					header = t('agreements.coinsurance');
					order = 1;
					break;
				case 'benefit_maximum':
					header = t('agreements.frequency');
					order = 4;
					break;
				case 'fiscal_maximum':
					header = t('agreements.max');
					order = 5;
					break;
				case 'health_pricing':
					if (['U', 'F'].includes(props.details.healthPricingType)) {
						order = 3;
						header = t('agreements.coveredAmount');
						content = props.details.healthPricingType === 'U' ? [] : item.descriptions;
					} else {
						show = false;
					}
			}
			return {
				id: item.type,
				order,
				header,
				content: content ? content : 'descriptions' in item ? item.descriptions : [],
				show
			};
		});
	if (props.details.prescriptionRequired) {
		items.push({
			order: 2,
			header: t('agreements.prescription'),
			content: [t('required')],
			show: true
		});
	}
	return items.sort((a, b) => (a.order > b.order ? 1 : -1));
});

// methods
function formatField(field) {
	const formattedField = { ...field };
	formattedField.content = field.content.map((item) =>
		!isNaN(item) ? formatCurrency(item) : item
	);
	return formattedField;
}
function formatCurrency(number) {
	return parseFloat(number).toLocaleString(root.$i18n.locale === 'fr' ? 'fr-CA' : 'en-CA', {
		style: 'currency',
		currency: 'CAD'
	});
}
async function relatedClick(clickedService) {
	emit('relatedClick', clickedService);
}
</script>

<template>
	<BRow class="my-4">
		<BCol lg="10">
			<BaseAlert alternate variant="grey" class="p-2">
				<span class="coverage-alert">{{ t('coverageFor') }} {{ participantName }}</span>
				<InfoModal
					class="d-inline"
					automation-id="covered-modal"
					:modal-title="t('coveredModal.title')"
					show-ok
				>
					<p class="mb-4">{{ t('coveredModal.content') }}</p>
				</InfoModal>
			</BaseAlert>
			<h1>{{ details.benefit.category?.description }}</h1>
			<h2 class="mb-3">{{ details.shortDescription }}</h2>
			<BaseCard class="card-content">
				<h3>{{ t('description') }}</h3>
				<p class="mb-4">{{ details.longDescription }}</p>
				<h3 class="smaller">{{ t('details') }}</h3>
				<div class="coverage-date mb-3">{{ t('date', { date: today }) }}</div>
				<BaseAlert
					v-if="'benefitWaitingPeriodDisclaimer' in details"
					alternate
					variant="warning"
					class="waiting-period-alert p-3"
				>
					<b class="d-block">{{ t('waitingPeriod') }}</b>
					<div
						v-for="wait in details.benefitWaitingPeriodDisclaimer.content"
						:key="wait"
						class="text"
					>
						{{ wait }}
					</div>
				</BaseAlert>

				<!-- DESKTOP VIEW -->
				<table class="benefit-detail d-none d-md-block">
					<tr v-for="item in tableItems" :key="item.order">
						<template v-if="item.show">
							<td class="heading">
								<template v-if="item.id === 'HEALTH_PRICING'">
									{{ item.header
									}}<InfoModal
										class="d-inline"
										automation-id="covered-amount-modal"
										:modal-title="t('coveredAmountModal.title')"
										show-ok
									>
										<p class="mb-4">{{ t('coveredAmountModal.desc1') }}</p>
									</InfoModal>
								</template>
								<template v-else>{{ item.header }}</template>
							</td>
							<td>
								<ul v-if="item.content?.length > 1">
									<li v-for="description in formatField(item).content" :key="description">
										{{ description }}
									</li>
								</ul>
								<template v-else>
									<template
										v-if="item.id === 'HEALTH_PRICING' && details.healthPricingType === 'U'"
									>
										{{ t('agreements.maximumEligible1') }}
										<TextModal
											id="customary-modal"
											:text="t('agreements.maximumEligible2')"
											:modal-title="t('customaryFeeModal.heading')"
											show-ok
											automation-id="ehb-details-text-modal"
										>
											<p class="mb-4">{{ t('customaryFeeModal.content') }}</p>
										</TextModal>
										{{ t('agreements.maximumEligible3') }}
									</template>
									<template v-else>{{ formatField(item).content[0] }}</template>
								</template>
							</td>
						</template>
					</tr>
					<tr v-if="details.ageConditionDisclaimer?.title">
						<td></td>
						<td>
							<p :class="{ 'mb-0': details.ageConditionDisclaimer.content.length === 1 }">
								<b>{{ details.ageConditionDisclaimer.title }}</b>
								{{ details.ageConditionDisclaimer.content[0] }}
							</p>
							<template v-if="details.ageConditionDisclaimer.content.length > 1">
								<p
									v-for="ageCondition in details.ageConditionDisclaimer.content"
									:key="ageCondition"
									:class="{ 'mb-0': index === details.ageConditionDisclaimer.content.length - 1 }"
								>
									<template v-if="index > 0">{{ ageCondition }}</template>
								</p>
							</template>
						</td>
					</tr>
				</table>

				<!-- MOBILE VIEW -->
				<div class="d-md-none">
					<template
						v-for="(item, index) in tableItems"
						class="benefit-detail-mobile"
						:aria-label="tableItemsAriaLabel"
					>
						<template v-if="item.show">
							<h4 v-if="item.id === 'HEALTH_PRICING'" :key="item.header" class="m-0">
								{{ item.header
								}}<InfoModal
									class="d-inline"
									automation-id="covered-amount-modal"
									:modal-title="t('coveredAmountModal.title')"
									show-ok
								>
									<p class="mb-4">{{ t('coveredAmountModal.desc1') }}</p>
								</InfoModal>
							</h4>
							<h4 v-else :key="item.header" class="m-0">
								{{ item.header }}
							</h4>
							<p
								v-if="item.id === 'HEALTH_PRICING' && details.healthPricingType === 'U'"
								:key="item.content[0]"
								:class="{ 'mb-0': tableItems?.length - 1 === index }"
							>
								{{ t('agreements.maximumEligible1') }}
								<TextModal
									id="customary-modal-mobile"
									:text="t('agreements.maximumEligible2')"
									:modal-title="t('customaryFeeModal.heading')"
									show-ok
									automation-id="ehb-details-text-modal-mobile"
								>
									<p class="mb-4">{{ t('customaryFeeModal.content') }}</p>
								</TextModal>
								{{ t('agreements.maximumEligible3') }}
							</p>
							<div v-else :key="item.content[0]">
								<ul
									v-if="item.content?.length > 1"
									:class="{ 'mb-3': index !== tableItems.length - 1 }"
								>
									<li v-for="content in formatField(item).content" :key="content">
										{{ content }}
									</li>
								</ul>
								<template v-else>
									<p :class="{ 'mb-0': tableItems?.length - 1 === index }">
										{{ formatField(item).content[0] }}
									</p>
								</template>
							</div>
						</template>
					</template>
					<template v-if="details.ageConditionDisclaimer?.title">
						<p :class="[{ 'mb-0': details.ageConditionDisclaimer.content.length === 1 }, 'mt-3']">
							<b>{{ details.ageConditionDisclaimer.title }}</b>
							{{ details.ageConditionDisclaimer.content[0] }}
						</p>
						<template v-if="details.ageConditionDisclaimer.content.length > 0">
							<p
								v-for="(ageCondition, index) in details.ageConditionDisclaimer.content"
								:key="ageCondition"
								:class="{ 'mb-0': index === details.ageConditionDisclaimer.content.length - 1 }"
							>
								<template v-if="index > 0">{{ ageCondition }}</template>
							</p>
						</template>
					</template>
				</div>
			</BaseCard>

			<ClaimRequirements
				v-if="details.claimRequirements?.length > 0"
				:claim-requirements="details.claimRequirements"
			/>

			<BaseCard v-if="details.relatedServices?.length > 0">
				<h3 class="smaller">{{ t('relatedServices') }}</h3>
				<BaseButton
					v-for="(service, index) in details.relatedServices"
					:key="index"
					:automation-id="`related-service-${index}`"
					variant="link"
					:class="['btn-link', { 'mb-2': index !== details.relatedServices.length - 1 }]"
					:pill="false"
					:label="titleCase(service.shortDescription)"
					@click="relatedClick(service)"
				/>
			</BaseCard>
		</BCol>
	</BRow>
</template>

<style lang="scss" scoped>
h1 {
	line-height: normal;
	font-family: $lato-font-family;
	font-weight: 300;
	font-size: 21px;
}
ul {
	margin-bottom: 0;
}
h3.smaller {
	font-size: 21px;
}
h4 {
	font-weight: bold;
	font-family: $lato-font-family;
	font-size: 17px;
}
td {
	vertical-align: top;
	padding: 5px 0;
}
td ::v-deep a,
p ::v-deep a {
	font-weight: 400;
	display: inline;
}
td span,
p span {
	display: inline-block;
}
.coverage-alert {
	font-family: $lato-font-family;
	font-size: 16px;
}
.coverage-date {
	color: #3c3c3c;
	font-weight: normal;
}
.card-content {
	font-weight: 300;
}
.benefit-detail {
	empty-cells: show;
	& .heading {
		font-weight: bold;
		padding-right: 3rem;
		white-space: nowrap;
		& div:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}
.btn-link {
	padding: 0;
	text-align: left;
	line-height: 1.2em;
	display: block;
}
::v-deep .waiting-period-alert .alert-title {
	font-family: $lato-font-family;
	font-size: 17px;
	& .text {
		font-weight: 300;
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
</style>

<i18n lang="json">
{
	"en": {
		"description": "Description",
		"details": "Coverage Details",
		"date": "As of {date}",
		"coverageFor": "Coverage for:",
		"agreements": {
			"maximumEligible1": "Maximum eligible expense is subject to the usual,",
			"maximumEligible2": "customary and reasonable fee",
			"maximumEligible3": "established by Blue Cross in the participant's province of residence.",
			"coveredAmount": "Covered amount",
			"coinsurance": "Percentage covered",
			"prescription": "Referral/prescription",
			"frequency": "Frequency",
			"max": "Maximum reimbursement"
		},
		"required": "Required.",
		"relatedServices": "Related benefits",
		"coveredModal": {
			"title": "Who's covered?",
			"content": "You're currently viewing coverage for the selected member of your plan. Other members of your plan may have different coverage. You can select another plan member to view by returning to the previous page."
		},
		"coveredAmountModal": {
			"title": "What is the covered amount?",
			"desc1": "The Covered Amount is the maximum cost that can be covered at the percentage shown. Costs above the maximum will not be covered."
		},
		"customaryFeeModal": {
			"heading": "What is the customary and reasonable fee?",
			"content": "When you claim this benefit, the amount we cover is limited based on the usual cost for this service in your province, as determined by Blue Cross. This is known as the \"customary and reasonable fee.\" If your provider charges more than the customary and reasonable fee, you'll need to pay the difference between your coverage amount and the cost of service. Check with your provider to find your service fee."
		},
		"waitingPeriod": "Waiting period in effect"
	},
	"fr": {
		"description": "Description",
		"details": "Modalités de la couverture",
		"date": "En date du {date}",
		"coverageFor": "Couverture pour :",
		"agreements": {
			"maximumEligible1": "Les frais maximaux admissibles sont assujettis aux",
			"maximumEligible2": "frais habituels, courants et raisonnables",
			"maximumEligible3": "établis par la Croix Bleue dans la province de résidence de l'adhérent.",
			"coveredAmount": "Montant admissible",
			"coinsurance": "Pourcentage couvert",
			"prescription": "Recommandation/Ordonnance",
			"frequency": "Fréquence",
			"max": "Remboursement maximal"
		},
		"required": "Requise.",
		"relatedServices": "Garanties connexes",
		"coveredModal": {
			"title": "Qui est couvert?",
			"content": "Vous consultez actuellement la couverture de la personne assurée sélectionnée. Les autres personnes couvertes par votre régime pourraient avoir une couverture différente. Vous pouvez sélectionner une autre personne assurée en revenant à la page précédente."
		},
		"coveredAmountModal": {
			"title": "Qu'est-ce que le montant couvert?",
			"desc1": "Le montant couvert correspond au montant maximal remboursé selon le pourcentage indiqué. Les coûts qui dépassent le maximum ne sont pas couverts."
		},
		"customaryFeeModal": {
			"heading": "En quoi consistent les frais usuels et raisonnables?",
			"content": "Lorsque vous présentez une demande de règlement en vertu de cette garantie, le montant couvert se limite au coût usuel du service dans votre province, déterminé par Croix Bleue. C’est ce qu’on appelle les « frais usuels et raisonnables ». Si votre fournisseur facture un montant plus élevé que les frais usuels et raisonnables, vous devrez payer la différence entre le montant couvert et le coût du service. Consultez votre fournisseur pour connaître les coûts du service."
		},
		"waitingPeriod": "Délai d'admission en vigueur"
	}
}
</i18n>
